import { Footer } from './Footer'

export default function Overlay() {
  return (
    <>
      <Footer
        
      />
    </>
  )
}
